<template>
  <Card
    :no-body-styles="!showCard"
    :is-full-height="true"
  >
    <template #body>
      <NuxtLink
        :class="containerClass"
        v-bind="linkComponentProps"
      >
        <ImageWithLabel
          :has-rounded-corners="!showCard"
          :image-subtitle="imageSubtitle"
          :image-url="imageUrl"
          :large="showCard"
          :title="title"
        />
      </NuxtLink>
    </template>
  </Card>
</template>

<script>
/**
 * News articles are rendered by OpenMountain-Web and navigated to with a <NuxtLink> so a relative
 * URL to keep the user in the SPA and avoid a page refresh.
 */
export default {
  name: 'PostLink',

  props: {
    post: {
      type: Object,
      required: true,
    },
    showCard: {
      type: Boolean,
      default: false,
    },
    showTimeAgo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    containerClass() {
      return 'tw-block tw-w-full tw-h-full hover:tw-no-underline';
    },

    imageUrl() {
      return this.post.image_url;
    },

    imageSubtitle() {
      if (!this.showTimeAgo) {
        return null;
      }

      const postedAt = this.$dayjs.utc(this.post.posted_at);
      const updatedAt = this.$dayjs.utc(this.post.updated_at);
      return updatedAt.isAfter(postedAt) ? updatedAt.timeAgo() : postedAt.timeAgo();
    },

    isNews() {
      return this.post.share_url.includes('/news/');
    },

    linkComponentProps() {
      const to = this.isNews
        ? `/news/post/${this.post.slug}`
        : new URL(this.post.share_url).pathname;

      return { to };
    },

    title() {
      return this.post.title;
    },
  },
};
</script>
